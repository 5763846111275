import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

class NewsContentsLoader {

	constructor() {
		this.ALL_NEWS_NODES = useStaticQuery(graphql`
			query {
				allMarkdownRemark(sort: {fields: frontmatter___sortKey, order: DESC}) {
					edges {
						node {
							frontmatter {
								year
								month
								date
								title
								appendix
							}
							html
							parent {
								... on File {
									name
								}
							}
						}
					}
				}
				allFile(filter: {extension: {eq: "pdf"}}) {
					edges {
						node {
							name
							publicURL
							relativePath
						}
					}
				}
			}
		`);
	}

	getContents = ( years ) => {
		if( !years || !Array.isArray( years ) || years.length === 0 ) {
			return;
		}
		const contents = [];
		for( let year of years ) {
			contents.push(
				this.getPerYearHeader( year ),
				this.getContentsBody( year ) );
		}
		return contents;
	};

	getPerYearHeader = ( year ) => {
		return (
			<div className="bridge column is-2">
				<p className="year">{year}</p>
			</div>
		)
	};

	getContentsBody = ( year ) => {
		let contents = [];
		for( let edge of this.ALL_NEWS_NODES.allMarkdownRemark.edges ) {
			if( year === edge.node.frontmatter.year ) {
				contents.push(
					this.getBodyWithAppendix( edge.node, this.ALL_NEWS_NODES.allFile ) );
			}
		}
		return contents;
	};

	getBodyWithAppendix = ( {frontmatter, html}, appendixFiles ) => {
		return (
			<div className="info column is-11 is-offset-1">
				<div className="box">
					<p className="subtitle is-6">{frontmatter.year}年&nbsp;{frontmatter.month}月&nbsp;{frontmatter.date}日</p>
					<h2 className="title is-5">{frontmatter.title}</h2>
					<div dangerouslySetInnerHTML={{ __html: html }}></div>
					{this.getAppendix(frontmatter.appendix, appendixFiles)}
				</div>
			</div>
		)
	};

	getAppendix = ( appendix, appendixFiles ) => {
		let result;
		if( appendix ) {
			for( let edge of appendixFiles.edges ) {
				if( appendix === edge.node.relativePath ) {
					result = <a href={edge.node.publicURL} download>{edge.node.name}</a>;
					break;
				}
			}
		}
		return result;
	};
}
export default NewsContentsLoader;
