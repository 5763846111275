import React from 'react'
import { Link } from 'gatsby'

import Config from '../config'
import Menu from '../modules/Menu'

const logo = {
  widthPx: 180,
  heightPx: 60,
  borderWidthPx: 6,
  borderColor: '#23F52A',
  backgroundColor: 'white'
};
const logoMainStyle = {
  width: logo.widthPx + 'px',
  height: logo.heightPx + 'px',
  padding: '0.8em',
  border: 'solid ' + logo.borderWidthPx + 'px ' + logo.borderColor,
  borderRadius: logo.borderWidthPx + 'px',
};
const logoCoverStyle = {
  width: ( logo.widthPx / 2 ) + 'px',
  height: ( logo.heightPx / 2 + 4 ) + 'px',
  marginTop: '-' + ( logo.heightPx / 2 + 2 ) + 'px',
  marginLeft: '-' + ( logo.widthPx + 2 ) + 'px',
  borderTop: 'solid ' + ( logo.borderWidthPx + 4 ) + 'px ' + logo.backgroundColor,
  borderLeft: 'solid ' + ( logo.borderWidthPx + 4 ) + 'px ' + logo.backgroundColor,
};


const toggleMenu = () => {
  const navBurger = document.querySelector( 'nav .navbar-burger' );
  navBurger.classList.toggle( 'is-active' );
  document.getElementById( navBurger.dataset.target ).classList.toggle( 'is-active' );
};


const Header = ({ siteTitle, currentName }) => (
  <nav
    className="navbar is-fixed-top is-transparent"
    role="navigation"
    aria-label="main navigation">
    <div className="container">

      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <span style={logoMainStyle}>{siteTitle}</span>
          <div style={logoCoverStyle}></div>
        </Link>

        <a className="navbar-burger"
          role="button" data-target="menuItems"
          aria-label="menu" aria-expanded="false"
          href={() => void(0)}
          onClick={toggleMenu}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className="navbar-menu" id="menuItems">
        <div className="navbar-end"
          dangerouslySetInnerHTML={{
              __html: Menu
                .createLinksWithCurrentText( currentName, Config.menu )
            }}/>
      </div>

    </div>
  </nav>
);
export default Header;
