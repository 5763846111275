import React from 'react'
import Layout from '../layout/basic.js'
import NewsContentsLoader from '../modules/NewsContentsLoader.js'

import '../css/news/style.scss'

const NewsPage = () => {
	return(
	  <Layout currentName="news">
	    <section className="news">

	      <article className="hero header-hero has-text-centered">
	        <div className="hero-body">
	          <div className="container">
	            <h1 className="title">ニュース</h1>
	            <h2 className="subtitle">NEWS</h2>
	          </div>
	        </div>
	      </article>

	      <article className="main-contents">
	        <div className="container columns is-multiline is-mobile">
				{new NewsContentsLoader().getContents( [ '2021', '2020' ] )}
	        </div>
	      </article>

	    </section>
	  </Layout>
	);
}
export default NewsPage;
