import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import '../css/all.scss'
import '../css/customize.scss'
import '../css/style.scss'

import Config from '../config'
import Header from '../components/header'
import Footer from '../components/footer'

const Layout = ( { currentName, children } ) => (
  <>
    <Helmet
      title={Config.header.title}
      meta={Config.header.meta}
      bodyAttributes={{ class: 'has-navbar-fixed-top' }}
    >
      <html lang="ja" />
    </Helmet>

    <Header siteTitle={Config.logo.text} currentName={currentName}/>
    <div className="site-content">{children}</div>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
